<template>
  <div>
    <b-sidebar
      v-if="$can('create', 'users')"
      id="add-new-user-sidebar"
      :visible="isAddNewUserSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Add New User
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="observer"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(validateForm)"
            @reset.prevent="resetForm"
          >

            <!-- username -->
            <b-form-group
              label="Username (No special characters, do not use spaces)"
              label-for="username"
            >
              <validation-provider
                #default="{ errors }"
                name="username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="userData.username"
                  :state="errors.length > 0 ? false:null"
                  name="username"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- first name -->
            <b-form-group
              label="First Name"
              label-for="first_name"
            >
              <validation-provider
                #default="{ errors }"
                name="first_name"
                rules="required"
              >
                <b-form-input
                  id="first_name"
                  v-model="userData.first_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Field: Last Name -->
            <b-form-group
              label="LastName"
              label-for="last_name"
            >
              <validation-provider
                #default="{ errors }"
                name="last_name"
                rules="required"
              >
                <b-form-input
                  id="last_name"
                  v-model="userData.last_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Field: Client Name -->
            <b-form-group
              v-if="clientNameProp === ''"
              label="Client Name"
              label-for="client_name"
            >
              <validation-provider
                #default="{ errors }"
                name="client_name"
                rules="required"
              >
                <v-select
                  v-model="userData.client_name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="clientsList"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="client_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="clientNameProp !== ''"
              label="Client Name"
              label-for="client_display_name"
            >
              <validation-provider
                #default="{ errors }"
                name="client_display_name"
                rules="required"
              >
                <b-form-input
                  id="client_display_name"
                  v-model="userData.client_display_name"
                  :readonly="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Field: Email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  type="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Field: Phone -->
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="userData.phone"
                  placeholder="optional"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Field: Role -->
            <b-form-group
              label="User Role"
              label-for="cognito_group"
            >
              <validation-provider
                #default="{ errors }"
                name="cognito_group"
                rules="required"
              >
                <v-select
                  v-model="userData.cognito_group"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="cognito_group"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Field: Region -->
            <b-form-group
              label="Region"
              label-for="region_loc"
            >
              <validation-provider
                #default="{ errors }"
                name="region_loc"
                rules="required"
              >
                <v-select
                  v-model="userData.region_loc"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="regionOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="region_loc"
                  rules="required"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Field: Status -->
            <b-form-group
              label="Status"
              label-for="is_active"
            >
              <validation-provider
                #default="{ errors }"
                name="is_active"
                rules="required"
              >
                <v-select
                  v-model="userData.is_active"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="is_active"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-if="$can('create', 'users')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <!-- New User Process Modal -->
    <!-- <b-modal
      id="vmodal"
      ref="vmodal"
      ok-title="Ok"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      size="lg"
      title="New User Created"
    >
      The user has been create. NOTE: an email has been sent to the user from no-reply@verificationemail.com with a temporary password. Please ensure that the user logs in and provides a new password with 48 hours or the account will have to be deleted and re-created.
    </b-modal> -->
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { ref } from '@vue/composition-api'
import { required, email } from '@validations'
// import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

// Notification
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    clientNameProp: {
      type: String,
      required: false,
      default: '',
    },
    clientDisplayNameProp: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      required,
      email,
      countries,
      blankUserData: {
        username: '',
        first_name: '',
        last_name: '',
        client_name: '',
        email: '',
        phone: '',
        cell_phone_carrier: null,
        cognito_group: null,
        is_active: true,
        is_onboarding: '',
        avatar: '',
        security_licence: '',
        abilities: [{}],
        authorized_clients: null,
        region_loc: 'All',
      },
      userData: {
        is_active: true,
      },
      clients: [],
      clientsList: [],
    }
  },
  created() {
    if (ability.can('create', 'users')) {
      if (this.clientNameProp !== '') {
        this.userData.client_name = this.clientNameProp
      }
      if (this.clientDisplayNameProp !== '') {
        this.userData.client_display_name = this.clientDisplayNameProp
      }
      if (typeof this.userData.region_loc === 'undefined') {
        this.userData.region_loc = 'All'
      }

      this.clientsList = []
      const qvars = {
        forceRefresh: false,
        q: '',
        perPage: 10,
        page: 1,
        sortBy: 'display_name',
        sortDesc: '',
        status: null,
      }
      store
        .dispatch('app-client/fetchClients', qvars)
        .then(response => {
          const { clients } = response.data
          clients.forEach(item => {
            this.clientsList.push({ label: item.display_name, value: item.clientname })
          })
          console.log('clientsList')
          console.log(this.clientsList)
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching clients list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },
  methods: {
    validateForm() {
      if (ability.can('create', 'users')) {
        // Perform custom validated and data manipulation
        let passedCustomValidation = true
        // force username to lower case
        this.userData.username = this.userData.username.toLowerCase().replaceAll(' ', '')
        // Check username for special characters
        const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
        if (format.test(this.userData.username)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding user information.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'No special characters allowed in username',
            },
          })
          passedCustomValidation = false
        }
        this.userData.role = this.userData.cognito_group
        // Determine the Client Display Name for the New User
        if (this.clientNameProp === '' || this.clientDisplayNameProp === '') {
          this.clientsList.forEach(item => {
            if (item.value === this.userData.client_name) {
              this.userData.client_display_name = item.label
            }
          })
        } else {
          this.userData.client_display_name = this.clientDisplayNameProp
        }
        // Remove ()- from phone number to properly format
        if (this.userData.phone !== undefined && this.userData.phone !== '' && this.userData.phone !== null) {
          this.userData.phone = this.userData.phone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '')
        }
        // Temporary Add: Adding additional fields to data set that will be included in for at later date
        this.userData.is_onboarding = false
        this.userData.avatar = ''
        this.userData.security_licence = ''
        this.userData.abilities = [{
          subject: 'cameras',
          actions: [
            {
              action: 'read',
            },
          ],
        }]

        this.userData.authorized_clients = []
        console.log(this.userData)
        if (passedCustomValidation) {
          this.$refs.observer.validate().then(success => {
            if (success) {
              store.dispatch('app-user/addUser', this.userData)
                .then(() => {
                  // console.log(this.$refs.vmodal)
                  // this.$refs.vmodal.show()
                  const nextRout = { name: 'apps-users-edit', params: { username: this.userData.username } }
                  // Route user
                  this.$router.replace(nextRout)
                    .then(() => {
                      this.$emit('update:is-add-new-user-sidebar-active', false)
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'New user created',
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: 'NOTE: Email sent to user from no-reply@verificationemail.com with a temporary password. Please update the user permissions to give user access.',
                        },
                      })
                    })
                })
                .catch(error => {
                  console.log(error)
                  console.log(error.response.data)
                  let theErrorStr = 'The following error(s) where received:\n'
                  if (typeof error.response.data.errors !== 'undefined') {
                    error.response.data.errors.forEach(item => {
                      theErrorStr += `${item.message}\n`
                    })
                  }
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error adding user information.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: theErrorStr,
                    },
                  })
                })
            }
          })
        }
      }
    },
    resetForm() {
      this.userData = JSON.parse(JSON.stringify(this.blankUserData))
      console.log(this.blankUserData)
      console.log(this.userData)
      this.$refs.observer.reset()
    },
  },
  setup(props) {
    let roleOptions = store.state.appSettings.cognito_group_internal
    if (props.clientNameProp !== '') {
      roleOptions = store.state.appSettings.cognito_group_clients
    }
    const regionOptions = store.state.appSettings.regions_loc

    const statusOptions = store.state.appSettings.is_active

    return {
      roleOptions,
      regionOptions,
      statusOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
