<template>

  <div
    v-if="$can('read', 'clients')"
    class="mb-2"
  >

    <client-sites-list-add-new
      :is-add-new-client-sites-sidebar-active.sync="isAddNewClientSitesSidebarActive"
      :client-name-prop="clientname"
      :client-display-name-prop="clientDisplayName"
      @refetch-data="refetchData"
      @newSiteAdded="refreshClientSitesList"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <!-- Filters -->
      <client-sites-list-filters
        :status-filter.sync="statusFilter"
        :status-options="statusOptions"
      />

      <div class="m-2">

        <!-- Table Top -->
        <!-- <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              class="mr-2"
              variant="primary"
              :disabled="this.$store.state.clients.fetchingClientSitesList"
              @click="refreshClientSitesList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh Sites List</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              v-if="$can('update', 'clients')"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="primary"
                @click="isAddNewClientSitesSidebarActive = true"
              >
                <feather-icon icon="UserPlusIcon" /><span class="text-nowrap ml-1">Add Site</span>
              </b-button>
            </div>
          </b-col>
        </b-row> -->

        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-2"
              variant="primary"
              :disabled="this.$store.state.clients.fetchingClientSitesList"
              @click="refreshClientSitesList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="primary"
              @click="isAddNewClientSitesSidebarActive = true"
            >
              <feather-icon icon="UserPlusIcon" /><span class="text-nowrap ml-1">Add Site</span>
            </b-button>
          </b-col>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refClientSitesListTable"
        class="position-relative"
        :items="fetchClientSites"
        responsive
        :fields="tableColumns"
        primary-key="sitename"
        :sort-by.sync="sortBy"
        show-empty
        empty-text=""
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
        style="min-height: 10rem;"
      >
        <template empty="">
          No Site found for this client
        </template>

        <!-- Column: Site Name-->
        <template #cell(sitename)="data">
          <div class="text-nowrap">
            <b-link
              :to="{ name: 'apps-client-sites-view', params: { clientname: data.item.clientname, sitename: data.item.sitename } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.sitename }}
            </b-link>
            <small class="text-muted">@{{ data.item.sitename }}</small>
          </div>
        </template>
        <!-- Column: phone -->
        <template #cell(phone)="data">
          <div class="text-nowrap">
            {{ data.item.phone }}
          </div>
        </template>
        <!-- Column: country -->
        <template #cell(country)="data">
          <div class="text-nowrap">
            {{ data.item.country }}
          </div>
        </template>
        <!-- Column: province -->
        <template #cell(province)="data">
          <div class="text-nowrap">
            {{ data.item.province }}
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveClientSitesStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="$can('read', 'clients')"
              :to="{ name: 'apps-client-sites-view', params: { clientname: data.item.clientname, sitename: data.item.sitename } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('update', 'clients')"
              :to="{ name: 'apps-client-sites-edit', params: { clientname: data.item.clientname, sitename: data.item.sitename } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', 'clients')"
              @click="deleteListItem(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalClientSites"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BDropdown, BDropdownItem, BPagination, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, watch, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ClientSitesListFilters from './ClientSitesListFilters.vue'
import useClientSitesList from './useClientSitesList'
import clientStoreModule from '../clientStoreModule'
import ClientSitesListAddNew from './ClientSitesListAddNew.vue'

export default {
  components: {
    ClientSitesListFilters,
    ClientSitesListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  // data() {
  //   return {
  //     clientSitesListData: [],
  //   }
  // },
  props: {
    clientSitesListData: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    clientname: {
      type: String,
      required: true,
    },
    clientDisplayName: {
      type: String,
      required: true,
    },
  },
  computed: {
    isFetchingClientList() {
      console.log('isFetchingClientList')
      console.log(this.$store.getters.getFetchingClientSitesList)
      return this.$store.getters.getFetchingClientSitesList
    },
  },
  setup(props) {
    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'

    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    const isAddNewClientSitesSidebarActive = ref(false)

    // const statusOptions = [
    //   { label: 'Pending', value: 'pending' },
    //   { label: 'Active', value: 'active' },
    //   { label: 'disabled', value: 'disabled' },
    // ]
    const statusOptions = store.state.appSettings.is_active

    const {
      fetchClientSites,
      tableColumns,
      perPage,
      currentPage,
      totalClientSites,
      clientSitesList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refClientSitesListTable,
      refetchData,

      // UI
      resolveClientSitesStatusVariant,

      // Extra Filters
      statusFilter,
    } = useClientSitesList(props.clientname)

    const updateClientSitesListData = e => {
      console.log('updateClientSitesListData')
      console.log(e)
      e.forEach(item => {
        props.clientSitesListData.push(item)
      })
    }

    watch(clientSitesList, updateClientSitesListData)

    return {
      // Sidebar
      isAddNewClientSitesSidebarActive,

      fetchClientSites,
      tableColumns,
      perPage,
      currentPage,
      totalClientSites,
      clientSitesList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refClientSitesListTable,
      refetchData,

      // UI
      resolveClientSitesStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
    }
  },
  methods: {
    refreshClientSitesList() {
      this.$store.state.clients.fetchingClientSitesList = true
      this.refetchData()
    },
    deleteListItem(item) {
      console.log(item)
      if (this.$ability.can('delete', 'clients')) {
        this.$bvModal
          .msgBoxConfirm(`Please confirm that you want to delete the ${item.sitename} site. NOTE: Any cameras associated to this site must be reassigned to another site before deleting.`, {
            title: 'Delete Site',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              store
                .dispatch('app-client/deleteClientSite', item)
                .then(response => {
                  console.log(response)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Site deleted successfully.',
                      icon: 'CheckSquareIcon',
                      variant: 'success',
                    },
                  })
                  this.refreshClientSitesList()
                })
                .catch(error => {
                  console.log(error)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error deleting Site. ${error}`,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
