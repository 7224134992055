<template>
  <b-card v-if="$can('read', 'clients')">

    <b-row>

      <!-- Client Info: Left col -->
      <b-col
        cols="21"
        xl="4"
        lg="4"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Client Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ clientData.display_name }}
              </h4>
            </div>
            <div
              v-if="$can('update', 'clients') && loggedInUserData.group === 'Management'"
              class="d-flex flex-wrap"
            >
              <b-button
                :to="{ name: 'apps-clients-edit', params: { clientname: clientData.clientname } }"
                variant="primary"
              >
                Edit
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="4"
        lg="4"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Client Name</span>
            </th>
            <td class="pb-50">
              {{ clientData.display_name }}
            </td>
          </tr>
          <tr v-if="clientData.parent_clientname && clientData.parent_clientname !==''">
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Parent Company</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientData.parent_clientname }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Phone</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientData.phone }}
            </td>
          </tr>
          <tr>
            <span><br></span>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Address</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientData.address }}
            </td>
          </tr>
          <tr v-if="clientData.addressUnit && clientData.addressUnit !==''">
            <th class="pb-50" />
            <td class="pb-50 text-capitalize">
              {{ clientData.addressUnit }}
            </td>
          </tr>
          <tr>
            <th class="pb-50" />
            <td class="pb-50 text-capitalize">
              {{ clientData.city }}, {{ clientData.province }}
            </td>
          </tr>
          <tr>
            <th class="pb-50" />
            <td class="pb-50 text-capitalize">
              {{ clientData.country }}
            </td>
          </tr>
          <tr>
            <th class="pb-50" />
            <td class="pb-50 text-capitalize">
              {{ clientData.postal }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard, BButton, BRow, BCol,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      servicesList: [],
    }
  },
  setup() {
    const loggedInUserData = getUserData()

    return {
      loggedInUserData,
    }
  },
}
</script>

<style>

</style>
