<template>
  <b-card v-if="$can('read', 'clients')">

    <b-row>
      <b-col>
        <div>
          <div>
            <div class="mb-1">
              <h4 class="mb-0">
                Notes
              </h4>
            </div>
            <div
              v-if="loggedInUserData.group === 'Management'"
            >
              <span class="font-weight-bold">Admin Notes</span>
              <b-form-textarea
                id="adminnote"
                v-model="clientData.notes_admin"
                class="mt-1 mb-2"
                rows="3"
                max-rows="6"
                readonly
              />
            </div>
            <div
              v-if="loggedInUserData.group === 'Management' || loggedInUserData.group === 'Client_Admin'"
            >
              <span class="font-weight-bold">Client Notes</span>
              <b-form-textarea
                id="adminnote"
                v-model="clientData.notes_client"
                class="mt-1 mb-2"
                rows="3"
                max-rows="6"
                readonly
              />
            </div>
            <div>
              <span class="font-weight-bold">Public Notes</span>
              <b-form-textarea
                id="adminnote"
                v-model="clientData.notes_public"
                class="mt-1 mb-2"
                rows="3"
                max-rows="6"
                readonly
              />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormTextarea,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard, BRow, BCol, BFormTextarea,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const loggedInUserData = getUserData()

    return {
      loggedInUserData,
    }
  },
  created() {
    if (typeof this.clientData.services === 'undefined') {
      this.clientData.services = {}
    }
  },
}
</script>

<style>

</style>
